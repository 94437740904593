<template>
    <div>
        <h1>
            Logging out.
        </h1>
        <h3>
            Please wait
        </h3>
        <v-icon>fas fa-circle-notch fa-spin</v-icon>
    </div>
</template>
<script>

export default {
    created() {
        this.logout(this);
    },
    methods: {
        async logout() {
            // check user
            let su = JSON.parse(localStorage.getItem('user'));
            if(!su)
            {
                this.$router.push({ name: 'Store'});
                return false;
            }
            this.$store.dispatch('logout').then(() => {
                // this.$router.push({ name: 'Store'});
                location.reload();
            });
        },
    }
}
</script>
